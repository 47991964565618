.message-lds-ring {
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
}

.message-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.message-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.message-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}