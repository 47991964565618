#root {
  height:100%;
  overflow: hidden;
}
.chatbot-container-landing-page {
  height: 90%;
  padding: 0.5rem;
  padding-right: 0px;
}
.chatbot-container-landing-page-mobile {
  height: 98% !important;
  padding: 0.5rem;
  /* padding-right: 0.5rem; */
  width: 96% !important;
  margin: 0.5rem !important;
}
.chatbot-container {
  position: absolute;
  right: 0;
  bottom: 10px;
  height: 32rem;
  padding: 0.5rem;
  padding-right: 0px;
  margin: 1rem;
  width: 24rem;
}

@media screen and (max-width: 768px) {
  .chatbot-container {
    width: 96% !important;
    height: 98vh !important;
    bottom: 0 !important;
    margin: 0.5rem !important;
  }
}

.custom-message-style .cs-message__content {
  background-color: lightgoldenrodyellow !important;
  color: #333;
  /* Add any other styling rules you need */
}

.shadow-gray-400 {
  /*box-shadow: 0 4px 6px -1px rgba(156, 163, 175, 0.1), 0 2px 4px -2px rgba(156, 163, 175, 0.05);*/
  /*box-shadow: 10px 10px 5px 1px lightblue;*/
  /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);*/
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

/*.typeindicatormargin {*/
/*  margin-left: 26px !important;*/
/*}*/

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 75px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 80%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}